import * as React from "react"
import { navigate } from "gatsby"
import { Box, Flex, Paragraph } from "theme-ui"
import useDrawerHook from "../../../hooks/useDrawerHook"
import { useAccountContext } from "../../../context/AccountContext"
import qs from "query-string"

import { CheckoutLayout } from "../../layouts-v2/checkout"
import { ProgressSteps, ProgressStepsItem } from "../ui/ProgressSteps"
import { Link } from "../Link"

import Details from "./Details"
import Shipping from "./Shipping"
import { ReviewAdyen } from "./ReviewAdyen"
import OrderSummary from "./OrderSummary"

import {
  restoreCartCheckoutState,
  CartCheckoutState,
} from "../../../utils/cart-state"
import { useStore } from "../../../context/NewStoreContext"
import LoginModal from "../../../components/new-ui/modal/login-modal"
import ReviewNoPayment from "./ReviewNoPayment"
import { useZendesk } from "../../../hooks/v2/useZendesk"
import DetailsGiftCard from "./DetailsGiftCard"
import { hasAdyenEnabled } from "../utils/cart/hasAdyenEnabled"

export interface CheckoutStepAction {
  previousStep: () => void
  previousStepLabel?: string
  nextStep?: () => void
  nextStepLabel?: string
  isInEditable?: boolean
  isSwap?: boolean
}

const NewCheckoutAdyen = () => {
  const store = useStore()
  const [step, setStep] = React.useState<CartCheckoutState>("details")
  const { loggedIn } = useAccountContext()
  const { drawerContent, setDrawerContent, setDrawerVisible } = useDrawerHook({
    config: {
      asModal: true,
      sx: { ".content": { backgroundColor: "grayscale.white" } },
    },
  })
  const [isCartEditable, setIsCartEditable] = React.useState<boolean>(false)
  const { isActivated, setIsActivated, ZendeskWidget } = useZendesk()
  const isGiftcardOnly =
    store?.cart?.items?.every((i) => i.is_giftcard === true) ?? false

  React.useEffect(() => {
    if (store?.cart?.id) {
      let { paymentRefused } = qs.parse(window.location.search)

      if (paymentRefused) {
        setStep("review")

        return
      }

      const step = restoreCartCheckoutState(store?.cart)

      if (step) {
        setStep(step)
      }
    }

    setDrawerContent(
      <LoginModal
        afterSubmit={() => {
          setDrawerVisible(false)
        }}
      />
    )
  }, [])

  React.useEffect(() => {
    const setPaymentSession = async () => {
      store.startCheckout.mutate(null, {
        onSuccess: (data) => {
          if (
            data.cart.payment_sessions.length &&
            data.cart.payment_sessions.some((ps) => ps.provider_id === "adyen")
          ) {
            store.pay.mutate({
              provider_id: "adyen",
            })
          }
        },
      })
    }

    if (
      store?.cart?.id &&
      !store?.pay?.isLoading &&
      !store?.startCheckout?.isLoading &&
      hasAdyenEnabled(store?.cart)
    ) {
      setPaymentSession()
    }
  }, [store?.cart])

  return store.cart?.id ? (
    <CheckoutLayout>
      <Box sx={{ display: [null, "flex"], minHeight: [null, "100vh"] }}>
        <OrderSummary
          order={store?.cart}
          canEdit={step === "review" ? false : true}
          isCartEditable={isCartEditable}
          setIsCartEditable={setIsCartEditable}
          step={step}
        />
        <Box
          sx={{
            position: "relative",
            width: [null, "55%"],
            paddingBlockStart: [6, 23],
            paddingBlockEnd: [16, 23],
            "&::after": {
              content: "''",
              width: "100%",
              height: "100%",
              position: "fixed",
              zIndex: 2,
              top: 0,
              left: 0,
              opacity: isCartEditable ? 1 : 0,
              visibility: isCartEditable ? "visible" : "hidden",
              backdropFilter: "blur(15px)",
              transition: "opacity .2s, visibility .2s",
            },
          }}
        >
          <Box
            sx={{
              position: [null, "sticky"],
              top: [null, 10],
              maxWidth: 147,
              marginInline: "auto",
              paddingInline: 4,
            }}
          >
            <Flex
              sx={{
                justifyContent: "space-between",
                marginBlockEnd: [10, 13],
              }}
            >
              <ProgressSteps>
                <ProgressStepsItem
                  isActive={step === "details"}
                  isClickable={
                    "shipping" === restoreCartCheckoutState(store?.cart) ||
                    "review" === restoreCartCheckoutState(store?.cart)
                      ? true
                      : false
                  }
                  onClick={() => {
                    const cartStep = restoreCartCheckoutState(store?.cart)

                    if (cartStep === "shipping" || cartStep === "review") {
                      setStep("details")
                    }
                  }}
                >
                  Details
                </ProgressStepsItem>
                <ProgressStepsItem
                  isActive={step === "shipping"}
                  isClickable={
                    "review" === restoreCartCheckoutState(store?.cart)
                      ? true
                      : false
                  }
                  onClick={() => {
                    const cartStep = restoreCartCheckoutState(store?.cart)

                    if (cartStep === "review") {
                      setStep("shipping")
                    }
                  }}
                >
                  Shipping
                </ProgressStepsItem>
                <ProgressStepsItem
                  isActive={step === "review"}
                  isClickable={
                    "review" === restoreCartCheckoutState(store?.cart)
                      ? true
                      : false
                  }
                  onClick={() => {
                    const cartStep = restoreCartCheckoutState(store?.cart)

                    if (cartStep === "review") {
                      setStep("review")
                    }
                  }}
                >
                  Review and payment
                </ProgressStepsItem>
              </ProgressSteps>
            </Flex>
            <Box sx={{ position: [null, "sticky"], top: [null, 0] }}>
              {step === "details" ? (
                isGiftcardOnly ? (
                  <DetailsGiftCard
                    previousStep={() => navigate("/")}
                    nextStep={() => setStep("shipping")}
                  />
                ) : (
                  <Details
                    previousStep={() => navigate("/")}
                    nextStep={() => setStep("shipping")}
                  />
                )
              ) : step === "shipping" ? (
                <Shipping
                  previousStep={() => setStep("details")}
                  nextStep={() => setStep("review")}
                />
              ) : step === "review" && store?.cart?.total > 0 ? (
                <ReviewAdyen previousStep={() => setStep("shipping")} />
              ) : step === "review" && store?.cart?.total <= 0 ? (
                <ReviewNoPayment previousStep={() => setStep("shipping")} />
              ) : null}
            </Box>
            <Box
              sx={{
                display: ["flex", "none"],
                order: 13,
                gap: 12,
                marginBlockStart: 12,
              }}
            >
              <Box sx={{ fontSize: "xs" }}>
                <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
                  Terms and conditions
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/shipping-and-delivery">Shipping</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/shipping-and-delivery">Return policy</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/terms-and-conditions">Terms and conditions</Link>
                </Paragraph>
                <Paragraph>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </Paragraph>
              </Box>
              <Box sx={{ fontSize: "xs" }}>
                <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
                  Need assistance?
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="mailto:customercare@teklafabrics.com">
                    E-mail us
                  </Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link onClick={() => setIsActivated(true)}>Chat</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="tel:+4552512545">+45 5251 2545 test2</Link>
                </Paragraph>
                <Paragraph>
                  USA: <Link to="tel:+19094872811">+1 (909) 487-2811</Link>
                </Paragraph>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {drawerContent}
      {isActivated && <ZendeskWidget />}
    </CheckoutLayout>
  ) : null
}

export default NewCheckoutAdyen
