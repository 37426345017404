import * as React from "react"
import { navigate } from "gatsby"
import { Box, Flex, Paragraph } from "theme-ui"
import useDrawerHook from "../../../hooks/useDrawerHook"
import { useAccountContext } from "../../../context/AccountContext"
import { Elements } from "@stripe/react-stripe-js"
import getStripe from "../../../utils/get-stripe"
import { BaseStripeElementsOptions } from "@stripe/stripe-js"

import { CheckoutLayout } from "../../layouts-v2/checkout"
import { ProgressSteps, ProgressStepsItem } from "../ui/ProgressSteps"
import { Link } from "../Link"

import Details from "./Details"
import Shipping from "./Shipping"
import Review from "./Review"
import OrderSummary from "./OrderSummary"

import {
  restoreCartCheckoutState,
  CartCheckoutState,
} from "../../../utils/cart-state"
import { useStore } from "../../../context/NewStoreContext"
import LoginModal from "../../../components/new-ui/modal/login-modal"
import ReviewNoPayment from "./ReviewNoPayment"
import { useZendesk } from "../../../hooks/v2/useZendesk"
import DetailsGiftCard from "./DetailsGiftCard"
import { hasAdyenEnabled } from "../utils/cart/hasAdyenEnabled"

export interface CheckoutStepAction {
  previousStep: () => void
  previousStepLabel?: string
  nextStep?: () => void
  nextStepLabel?: string
  isInEditable?: boolean
  setClientSecret?: (clientSecret: string) => void
  isSwap?: boolean
}

const stripeOptions: BaseStripeElementsOptions = {
  fonts: [
    {
      family: "Unica77",
      src: "../../../fonts/Unica77/Unica77LLWeb-Light.woff2",
    },
    {
      family: "Unica77",
      src: "url(../../../fonts/Unica77/Unica77LLWeb-Light.woff)",
      weight: "400",
    },
  ],
  appearance: {
    theme: "stripe",
    labels: "floating",
    variables: {
      fontWeightNormal: "400",
      colorText: "#454545",
      colorTextPlaceholder: "#9e9e9d",
      borderRadius: "0",
      // @ts-expect-error
      borderColor: "#e3e3e3",
      fontFamily: `"Unica77", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif`,
    },
    rules: {
      ".Label--resting, .Label--floating": {
        color: "#9e9e9d",
      },
      ".Input": {
        boxShadow: "none",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      ".Input:focus": {
        boxShadow: "none",
        borderColor: "#e3e3e3",
      },
    },
  },
}

const NewCheckout = () => {
  const store = useStore()
  const [step, setStep] = React.useState<CartCheckoutState>("details")
  const { loggedIn } = useAccountContext()
  const { drawerContent, setDrawerContent, setDrawerVisible } = useDrawerHook({
    config: {
      asModal: true,
      sx: { ".content": { backgroundColor: "grayscale.white" } },
    },
  })
  const [clientSecret, setClientSecret] = React.useState<string>(null)
  const [isCartEditable, setIsCartEditable] = React.useState<boolean>(false)
  const { isActivated, setIsActivated, ZendeskWidget } = useZendesk()
  const isGiftcardOnly =
    store?.cart?.items?.every((i) => i.is_giftcard === true) ?? false

  React.useEffect(() => {
    if (store?.cart?.id) {
      const step = restoreCartCheckoutState(store?.cart)

      if (step) {
        setStep(step)
      }
    }

    setDrawerContent(
      <LoginModal
        afterSubmit={() => {
          setDrawerVisible(false)
        }}
      />
    )
  }, [])

  React.useEffect(() => {
    const setPaymentSession = async () => {
      if (!store?.cart?.payment_sessions?.length) {
        await store.startCheckout.mutateAsync()
      }

      const stripeSession = store.cart?.payment_sessions?.find(
        (session) => session.provider_id === "stripe"
      )

      if (!stripeSession) {
        return
      }

      if (
        !clientSecret ||
        clientSecret !== stripeSession?.data?.client_secret
      ) {
        if (stripeSession?.data?.client_secret) {
          setClientSecret(stripeSession?.data?.client_secret as string)
        } else {
          const res = await store?.pay?.mutateAsync({
            provider_id: "stripe",
          })

          if (res?.cart?.payment_session?.data?.client_secret) {
            setClientSecret(
              res.cart.payment_session.data.client_secret as string
            )
          }
        }
      }
    }

    if (
      store?.cart?.id &&
      !store?.pay?.isLoading &&
      !store?.startCheckout?.isLoading &&
      !hasAdyenEnabled(store.cart)
    ) {
      setPaymentSession()
    }
  }, [store?.cart])

  return store.cart?.id ? (
    <CheckoutLayout>
      <Box sx={{ display: [null, "flex"], minHeight: [null, "100vh"] }}>
        <OrderSummary
          order={store?.cart}
          canEdit={step === "review" ? false : true}
          isCartEditable={isCartEditable}
          setIsCartEditable={setIsCartEditable}
          step={step}
        />
        <Box
          sx={{
            position: "relative",
            width: [null, "55%"],
            paddingBlockStart: [6, 23],
            paddingBlockEnd: [16, 23],
            "&::after": {
              content: "''",
              width: "100%",
              height: "100%",
              position: "fixed",
              zIndex: 2,
              top: 0,
              left: 0,
              opacity: isCartEditable ? 1 : 0,
              visibility: isCartEditable ? "visible" : "hidden",
              backdropFilter: "blur(15px)",
              transition: "opacity .2s, visibility .2s",
            },
          }}
        >
          <Box
            sx={{
              position: [null, "sticky"],
              top: [null, 10],
              maxWidth: 147,
              marginInline: "auto",
              paddingInline: 4,
            }}
          >
            <Flex
              sx={{
                justifyContent: "space-between",
                marginBlockEnd: [10, 13],
              }}
            >
              <ProgressSteps>
                <ProgressStepsItem
                  isActive={step === "details"}
                  isClickable={
                    "shipping" === restoreCartCheckoutState(store?.cart) ||
                    "review" === restoreCartCheckoutState(store?.cart)
                      ? true
                      : false
                  }
                  onClick={() => {
                    const cartStep = restoreCartCheckoutState(store?.cart)

                    if (cartStep === "shipping" || cartStep === "review") {
                      setStep("details")
                    }
                  }}
                >
                  Details
                </ProgressStepsItem>
                <ProgressStepsItem
                  isActive={step === "shipping"}
                  isClickable={
                    "review" === restoreCartCheckoutState(store?.cart)
                      ? true
                      : false
                  }
                  onClick={() => {
                    const cartStep = restoreCartCheckoutState(store?.cart)

                    if (cartStep === "review") {
                      setStep("shipping")
                    }
                  }}
                >
                  Shipping
                </ProgressStepsItem>
                <ProgressStepsItem
                  isActive={step === "review"}
                  isClickable={
                    "review" === restoreCartCheckoutState(store?.cart)
                      ? true
                      : false
                  }
                  onClick={() => {
                    const cartStep = restoreCartCheckoutState(store?.cart)

                    if (cartStep === "review") {
                      setStep("review")
                    }
                  }}
                >
                  Review and payment
                </ProgressStepsItem>
              </ProgressSteps>
            </Flex>
            <Box sx={{ position: [null, "sticky"], top: [null, 0] }}>
              {step === "details" ? (
                isGiftcardOnly ? (
                  <DetailsGiftCard
                    previousStep={() => navigate("/")}
                    nextStep={() => setStep("shipping")}
                  />
                ) : (
                  <Details
                    previousStep={() => navigate("/")}
                    nextStep={() => setStep("shipping")}
                  />
                )
              ) : step === "shipping" ? (
                <Shipping
                  previousStep={() => setStep("details")}
                  nextStep={() => setStep("review")}
                />
              ) : step === "review" &&
                clientSecret &&
                store?.cart?.total > 0 ? (
                <Elements
                  stripe={getStripe()}
                  options={{
                    ...stripeOptions,
                    clientSecret,
                  }}
                  key={clientSecret}
                >
                  <Review
                    previousStep={() => setStep("shipping")}
                    setClientSecret={setClientSecret}
                  />
                </Elements>
              ) : step === "review" && store?.cart?.total <= 0 ? (
                <ReviewNoPayment
                  previousStep={() => setStep("shipping")}
                  setClientSecret={null}
                />
              ) : null}
            </Box>
            <Box
              sx={{
                display: ["flex", "none"],
                order: 13,
                gap: 12,
                marginBlockStart: 12,
              }}
            >
              <Box sx={{ fontSize: "xs" }}>
                <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
                  Terms and conditions
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/shipping-and-delivery">Shipping</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/shipping-and-delivery">Return policy</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/terms-and-conditions">Terms and conditions</Link>
                </Paragraph>
                <Paragraph>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </Paragraph>
              </Box>
              <Box sx={{ fontSize: "xs" }}>
                <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
                  Need assistance?
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="mailto:customercare@teklafabrics.com">
                    E-mail us
                  </Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link onClick={() => setIsActivated(true)}>Chat</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="tel:+4552512545">+45 5251 2545 test</Link>
                </Paragraph>
                <Paragraph>
                  USA: <Link to="tel:+19094872811">+1 (909) 487-2811</Link>
                </Paragraph>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {drawerContent}
      {isActivated && <ZendeskWidget />}
    </CheckoutLayout>
  ) : null
}

export default NewCheckout
